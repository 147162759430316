/* eslint-disable ember/no-classic-components, ember/no-mixins, ember/no-classic-classes, ember/require-tagless-components, ember/no-get */
import { sort } from '@ember/object/computed';
import { computed } from '@ember/object';
import Component from '@ember/component';
import SortBy from 'shared/mixins/sort-by';
import ReportEvents from 'shared/mixins/report-events';

import { inject as service } from '@ember/service';
import layout from './template';

export default Component.extend(SortBy, ReportEvents, {
  layout,

  ajax: service(),
  currentSession: service(),

  sortProperties: ['created_at'],
  sortAscending: false,

  sortedReports: sort('reports', 'sortOrder'),

  requisitionStatusValid: computed('requisition.{status,signatureRequired}', function() {
    let overallStatus = this.requisition.get('status');
    let signatureRequired = this.requisition.get('signatureRequired');

    return !['held', 'canceled'].includes(overallStatus) && !signatureRequired;
  }),

  filteredReports: computed('sortedReports', 'archived', 'sortedReports.@each.archived', function() {
    let sortedReports = this.get('sortedReports');
    let showArchived = this.get('archived');
    if (showArchived) {
      return sortedReports;
    } else {
      return sortedReports.filterBy('archived', false);
    }
  })

});
